import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';

const CarCard = ({ car }) => {
    const { t } = useTranslation();

    return (
        <div className="c-car-card" style={{

            // flex: 1
        }}>
            <Link className="c-car-card__inner" to={`/cars/` + car.slug} >
                {
                    car.coverImage &&
                    <GatsbyImage
                        image={car.coverImage.localFile.childImageSharp.gatsbyImageData}
                        width={100}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Logo"
                    />
                }
                <div class="c-car-card__specifications">
                    <p class="c-car-card__title c-car-card__title--make">{car.mayke?.name ?? '\u00A0'}</p>
                    <p class="c-car-card__title c-car-card__title--model">{car.title}</p>
                    <div className="c-car-card__spec--horizontal">
                        <div>
                            <span className="spec-label">{t('Transmission')}</span>
                            <span>{car.transmission?.name}</span>
                        </div>
                        <div>
                            <span className="spec-label">{t('FuelType')}</span>
                            <span>{car.fuel_type?.name}</span>
                        </div>
                        <div>
                            <span className="spec-label">{t('Mileage')}</span>
                            <span>{car.mileage}</span>
                        </div>
                        <div>
                            <span className="spec-label">{t('YearOfConstruction')}</span>
                            <span>{car.firstRegistration}</span>
                        </div>
                    </div>
                    {
                        car.price &&
                        <span class="c-car-card__price u-one-third">&euro; {car.price},-</span>
                    }
                </div>
            </Link>
        </div>
    )
}

export default CarCard
