import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Link, Trans, useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import Seo from "../components/seo"
import CarCard from "../components/car-card"

const IndexPage = ({data}) => {
  const { t } = useTranslation();

  const { hero } = data.strapiHomepage;
  const { cars } = data.allStrapiCar;

  return (
    // <Layout location={location}>
    <Layout>
      <Seo title="Home" homeTitle="Autohunt" />
      {/* <h1>{hero.title}</h1> */}
      <div className="hero-container site-width">
        <GatsbyImage
          image={hero.image.localFile.childImageSharp.gatsbyImageData}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Logo"
          style={{
            width: `100vw`,
            height: `100vh`,
            position: `relative`,
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '50vw'
            // left: `calc(-50vw + 50%)`
          }}
        />
        <div className="hero-overlay">
        </div>
        <div className="hero-subtitle-container">
          <p className="hero-subtitle">{t('HomepageTitle')}</p>
          <Link className="btn btn--ghost" to="/cars">
            {/* {t('Explore Our Cars')} */}
            <Trans>Explore Our Cars</Trans>
          </Link>
          <div className="hero-reviews">
            <p className="hero-reviews-title"><a style={{ color: '#FFF' }} target="_blank" rel="noreferrer" href="https://www.autoscout24.be/nl/verkopers/autohunt">Autoscout</a> 5/5 | 33 reviews</p>
            <div className="hero-review-stars">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>
          </div>
        </div>
      </div>
      <section className="c-block l-wrap" style={{ paddingTop: '48px' }}>

        <div className="site-width">
          <h2 className="c-block__title">{t('New')}</h2>
          <div className="c-block__content c-block__content--cars">
            {cars.map(c => {
              return <CarCard
                car={c.node}
                key={c.node.slug}
              />
            })}
          </div>
        </div>
      </section>

      <section className="c-block c-block__services l-wrap" style={{ backgroundColor: '#FFF' }}>

        <div className="site-width">
          <h2 className="c-block__title">{t('Buy your next car worry-free')}</h2>
          <ol className="c-block__content c-service-list">
            <li className="c-service-list__item">
              <svg className="service-item__icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="feather feather-compass"><circle cx="12" cy="12" r="10"></circle><polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon></svg>
              <span className="service-item__title">1. {t('CarPass')}</span>
              <p className="service-item__description">{t('CarPassDescription')}</p>
            </li>
            <li className="c-service-list__item">
              <svg className="service-item__icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              <span className="service-item__title">2. {t('AutohuntCheckup')}</span>
              <p className="service-item__description">{t('AutohuntCheckupDescription')}</p>
            </li>
            <li className="c-service-list__item">
              <svg className="service-item__icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="feather feather-shield"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>
              <span className="service-item__title">3. {t('OneYearWarranty')}</span>
              <p className="service-item__description">{t('OneYearWarrantyDescription')}</p>
            </li>
          </ol>
        </div>
      </section>

      <section className="c-block c-block__takeover">
        <div className="takeover-text-block">
          <h2 className="c-block__title">{t('Takeover')}</h2>
          <div className="takeover-text-block__content">
            <p>{t('Always on the lookout for second hand cars')}</p>
            <div>
              <Link to="/sell-your-car" className="btn btn--ghost btn--icon">
                {t('Ask price')}
                <svg className xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="takeover-image">
          <StaticImage
            layout="full-width"
            src="../images/Mini stuur.jpg" alt="Range Rover" />
        </div>
      </section>

      <section className="c-block c-block__happy-customers">
        <div className="site-width">
          <div className="quote-container" style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100px', marginRight: '32px' }}>
              <svg fill="#fff">
                <path d="M30.512,43.939c-2.348-0.676-4.696-1.019-6.98-1.019c-3.527,0-6.47,0.806-8.752,1.793
			c2.2-8.054,7.485-21.951,18.013-23.516c0.975-0.145,1.774-0.85,2.04-1.799l2.301-8.23c0.194-0.696,0.079-1.441-0.318-2.045
			s-1.035-1.007-1.75-1.105c-0.777-0.106-1.569-0.16-2.354-0.16c-12.637,0-25.152,13.19-30.433,32.076
			c-3.1,11.08-4.009,27.738,3.627,38.223c4.273,5.867,10.507,9,18.529,9.313c0.033,0.001,0.065,0.002,0.098,0.002
			c9.898,0,18.675-6.666,21.345-16.209c1.595-5.705,0.874-11.688-2.032-16.851C40.971,49.307,36.236,45.586,30.512,43.939z"/>
                <path d="M92.471,54.413c-2.875-5.106-7.61-8.827-13.334-10.474c-2.348-0.676-4.696-1.019-6.979-1.019
			c-3.527,0-6.471,0.806-8.753,1.793c2.2-8.054,7.485-21.951,18.014-23.516c0.975-0.145,1.773-0.85,2.04-1.799l2.301-8.23
			c0.194-0.696,0.079-1.441-0.318-2.045c-0.396-0.604-1.034-1.007-1.75-1.105c-0.776-0.106-1.568-0.16-2.354-0.16
			c-12.637,0-25.152,13.19-30.434,32.076c-3.099,11.08-4.008,27.738,3.629,38.225c4.272,5.866,10.507,9,18.528,9.312
			c0.033,0.001,0.065,0.002,0.099,0.002c9.897,0,18.675-6.666,21.345-16.209C96.098,65.559,95.376,59.575,92.471,54.413z"/>
              </svg>
            </div>
            <p className="customer-quote" style={{ margin: 0 }}>
              {t('Review1')}
            </p>
          </div>
        </div>
      </section>

      <section className="c-block c-block__call-us">
        <div className="site-width" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <h2 className="c-block__title" style={{ width: '100%', justifyContent: 'center', fontSize: '24px' }}>{t('AnyQuestions')}</h2>
          <div className="call-us-button-container" style={{ display: 'flex', margin: 'auto' }}>
            <button className="btn btn--ghost btn--icon btn--mail" style={{
              fontSize: '16px',
              fontWeight: '600',
              border: '3px solid white',
              alignContent: 'center'
            }}>
              <svg style={{ marginLeft: '0px', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
              axel@autohunt.be
            </button>

            <button className="btn btn--ghost btn--icon btn--phone" style={{
              fontSize: '16px',
              fontWeight: '600',
              border: '3px solid white',
              alignContent: 'center'
            }}>
              <svg style={{ marginLeft: '0px', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
              +32 472 37 99 80
            </button>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default IndexPage

// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: {ns: {in: ["common", "404"]}, language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;


export const query = graphql`
query ($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        },
        strapiHomepage {
          hero {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, breakpoints: [450, 750, 1024, 1920])
                }
              }
            }
          }
        },
        allStrapiCar(limit: 3, filter: {locale: {eq: $language}, sellingDate: {eq: null}}, sort: {fields: published_at, order: DESC}) {
          cars: edges {
            node {
              firstRegistration
              slug
              title
              mileage
              year
              price
              fuel_type {
                name
              }
              make {
                name
              }
              transmission {
                name
              }
              coverImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
`
